'use strict';
/* global pageContext, window */

var utils = require('org/util/utils');

var dataLayer = window.dataLayer;
var dataLayerUrls = window.dataLayerUrls;
var dataLayerHelper = window.dataLayerHelper;

var ATC_LOCATION = {
    PDP: 'PDP',
    SEARCH: 'Product Listing',
    QUICKVIEW: 'Quickview',
    SINGLEPAGEORDER: 'One Page Order Sheet',
    RECOMMENDATION: 'Recommendation Widget',
    FAVORITES: 'Favorites',
    CART: 'Cart',
    STARTERKITS: 'Starter Kits',
    MYLISTOVERVIEW: 'my list overview',
    MYLISTDETAILS: 'my list details',
    SHAREDLISTOVERVIEW: 'shared list overview',
    SHAREDLISTDETAILS: 'shared list details',
    ORDERBYPART: 'Order By Part'
};

var CONSULTANT_FAILED = {
    INVALID_PHONE_NUMBER: 'invalid phone number',
    IBC_NOT_FOUND: 'ibc not found',
    IBC_INELIGIBLE: 'ibc ineligible'
};

var CHECKOUT_STEPS = {
    CONTESTS: 'contests',
    SHIPPING: 'shipping',
    PAYMENT: 'payment'
};

var CONSULTANT_METHOD = {
    PHONE_NUMBER: 'phone number',
    ZIP_CODE: 'zip code'
};

/**
 * @description add First Product To Emppty Cart
 */
function addFirstProductToEmptyCart() {
    var obj = {
        event: 'e_openCart'
    };

    dataLayer.push(obj);
}

/**
 * @description Copy Sharelist ID
 */
function copyShareListID() {
    var obj = {
        event: 'e_shareListCopyId'
    };

    dataLayer.push(obj);
}


/**
 * @description Share List is imported
 */
function shareListImport() {
    var obj = {
        event: 'e_shareListImport'
    };

    dataLayer.push(obj);
}

/**
 * @param {Object} productObject gtm product data
 * @description click on AddToFavourites on PDP
 */
function productAddToFavourites(productObject) {
    var obj = {
        event: 'e_addToFav',
        favoritedProduct: productObject
    };

    dataLayer.push(obj);
}

/**
 * @param {string} sectionName section name
 * @description product Expand section
 */
function productExpandSection(sectionName) {
    var obj = {
        event: 'e_productSectionExpanded',
        sectionName: sectionName
    };

    dataLayer.push(obj);
}

/**
 * @param {string} viewImage viewed image
 * @description product Image Interaction
 */
function productImageInteraction() {
    var obj = {
        event: 'e_productImageInteraction',
        imageInteractionMethod: 'View image'
    };

    dataLayer.push(obj);
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for a product click
 */
function quickviewOpen(productObject) {
    var obj = {
        event: 'e_quickviewOpen',
        viewedProduct: productObject
    };

    dataLayer.push(obj);
}

/**
 * @param {string} sort type
 * @description gets the data for changing sort
 */
function sortChange(sort) {
    var obj = {
        event: 'e_searchSorted',
        sort: sort
    };

    dataLayer.push(obj);
}


/**applyPromoCode
 * @param {string} couponCode coupon code
 * @param {boolean} success status
 * @description gets the data for apply promo code event
 */
function applyPromoCode(couponCode, success) {
    var obj = {
        event: 'apply_promo_code',
        coupon: couponCode,
        status: success ? 'success' : 'fail'
    };
    dataLayer.push(obj);
}

/** connectConsultant
 * @param {Object} consultant consultant object
 * @param {string|null} consultantMethod - method is optional, CONSULTANT_METHOD.PHONE_NUMBER by default
 * @description gets the data for connect consultant event
 */
function connectConsultant(consultant, consultantMethod) {
    var obj = {
        event: 'ibc_connect',
        referringIbcID: consultant.consultantId + consultant.consultantSuffix,
        method: consultantMethod || CONSULTANT_METHOD.PHONE_NUMBER
    };
    dataLayer.push(obj);
}
/** connectConsultantFailed
 * @param {string} reason failed reason
 * @description gets the data for connect consultant falure event
 */
function connectConsultantFailed(reason) {
    var obj = {
        event: 'error',
        errorType: 'form error',
        errorMessage: reason
    };
    dataLayer.push(obj);
}

/**
 *
 * @param {string} variable - variable name
 * @param {string} queryString - query string
 * @return {Object} - return boolean or variable value
 */
function getQueryVariable(variable, queryString) {
    var query = queryString;
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
}

/**
 * @param {string} query type
 * @description gets the data for refinement Change
 */
function refinementChange(query) {
    var dyncat = getQueryVariable('dyncat', query);
    if (dyncat) {
        dataLayer.forEach(function (val) {
            if (val.category) {
                val.category = dyncat;
            }
        });
    }
}

/**
 * @description gets the data for finding filter
 * @return {Object} - categoryArray array with categories
 */
function findFilters() {
    var categories = $('.refinement-bar .refinements .collapsible-sm');
    var categoryArray = [];
    for (var i = 0; i < categories.length; i++) {
        var category = categories[i];
        var categoryHeader = $(category).find('.card-header');
        var categoryName = '';
        var selectedValues = '';
        if (categoryHeader.length > 0) {
            categoryName = $.trim(categoryHeader.text());
            var selectedFilters = $(category).find('svg[class*="icon-radio-selected"], svg[class*="icon-checkbox-selected"]');
            for (var j = 0; j < selectedFilters.length; j++) {
                var selectedSpan = $(selectedFilters[j]).closest('button').find('span[aria-hidden|="true"]');
                var hitCount = $(selectedFilters[j]).closest('button').find('span[aria-hidden|="true"] .refinement-hitcount');
                if (selectedValues !== '') {
                    selectedValues += '|' + $.trim($(selectedSpan).text());
                } else {
                    selectedValues = $.trim($(selectedSpan).text());
                }
                if (hitCount.length && hitCount.text()) {
                    selectedValues = $.trim(selectedValues.replace(hitCount.text(), ''));
                }
            }
        }

        if (selectedValues.length > 0) {
            categoryArray.push({
                type: categoryName,
                value: selectedValues
            });
        }
    }

    return categoryArray;
}

/**
 * @description gets the data for applying filter
 */
function filterApply() {
    var filterArray = findFilters();
    if (filterArray.length > 0) {
        var obj = {
            event: 'e_searchFiltered',
            filters: filterArray
        };

        dataLayer.push(obj);
    }
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for a product click
 */
function productClick(productObject) {
    var obj = {
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: {
                    list: pageContext.type
                },
                products: []
            }
        }
    };

    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for a wishlist click
 */
function wishlistClick(productObject) {
    var obj = {
        event: 'wishlistClick',
        ecommerce: {
            click: {
                actionField: {
                    list: pageContext.type
                },
                products: []
            }
        }
    };

    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for product compare click
 */
function compareClick(productObject) {
    var obj = {
        event: 'compareClick',
        ecommerce: {
            click: {
                actionField: {
                    list: pageContext.type
                },
                products: []
            }
        }
    };

    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {string} quantity product quantity
 * @param {string} atcLocation location where product was added to the cart
 */
function addToCart(productObject, quantity, atcLocation) {
    var quantityObj = {
        quantity: quantity
    };
    var obj = {
        event: 'e_addToCart',
        atcLocation: atcLocation,
        ecommerce: {
            currencyCode: dataLayerHelper.currencyCode,
            add: {
                products: []
            }
        }
    };
    obj.ecommerce.add.products.push($.extend(productObject, quantityObj));
    dataLayer.push(obj);
}

/**
 * @description Click event for add several products to cart
 * @param {Object} products The product data
 * @param {string} atcLocation location where product was added to the cart
 */
function addMultyProductToCart(products, atcLocation) {
    var obj = {
        event: 'e_addToCart',
        atcLocation: atcLocation,
        ecommerce: {
            currencyCode: dataLayerHelper.currencyCode,
            add: {
                products: products
            }
        }
    };
    dataLayer.push(obj);
}

/**
 *
 * @param {Object} productObject product object
 * @param {string|number} quantity product quantity
 */
function removeFromCart(productObject, quantity) {
    var quantityObj = {
        quantity: quantity
    };

    var obj = {
        event: 'e_removeFromCart',
        ecommerce: {
            currencyCode: dataLayerHelper.currencyCode,
            remove: {
                products: []
            }
        }
    };

    obj.ecommerce.remove.products.push($.extend(productObject, quantityObj));
    dataLayer.push(obj);
}

/**
 *
 * @param {Object} productList list of products which will be removed
 */
function removeAllFromCart(productList) {
    var obj = {
        event: 'e_removeFromCart',
        ecommerce: {
            currencyCode: dataLayerHelper.currencyCode,
            remove: {
                products: productList
            }
        }
    };

    dataLayer.push(obj);
}

/**
 * Updates the current step in the checkout flow
 * @param {Integer} step the step number the flow is currently on
 */
function updateCheckoutStep(step) {
    if (step && step === CHECKOUT_STEPS.CONTESTS) {
        return;
    }
    var actionUrl = dataLayerUrls.getProductsOfCheckoutStep;
    var url = utils.appendParamToURL(actionUrl, 'checkoutStep', step);
    $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
            var obj = {
                event: 'e_checkout',
                ecommerce: {
                    currencyCode: data.currencyCode,
                    checkout: {
                        actionField: {
                            step: data.checkoutData.step.toString()
                        },
                        products: data.checkoutData.products
                    }
                }
            };
            dataLayer.push(obj);
        }
    });
}

/**
 * @description Convenience method for creating a click event.
 * @param {string} event event
 * @param {string} eventCategory event categroy
 * @param {string} eventAction event action
 * @param {string} eventLabel event layer
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    });
}

/**
 *
 * @param {Object} address entered by user
 */
function pickupEnterLocation(address) {
    var obj = {
        event: 'e_mapEnterLocation',
        userLocation: {
            city: address.city,
            state: address.state,
            zip: address.zip
        }
    };

    dataLayer.push(obj);
}

/**
 *
 * @param {string} eventName - eventName
 * @param {Object} location - location
 */
function selectLocation(eventName, location) {
    var obj = {
        event: eventName,
        userLocation: {
            city: location.city ? location.city : '',
            state: location.stateCode ? location.stateCode : '',
            zip: location.postalCode ? location.postalCode : ''
        },
        pickupPoint: {
            name: location.name,
            type: location.storeType
        }
    };

    dataLayer.push(obj);
}

/**
 * @description e_shareList event after share list success.
 */
function shareList() {
    var obj = {
        event: 'e_shareList'
    };

    dataLayer.push(obj);
}

var events = {
    account: function () {},
    cart: function () {
        $('body').on('cart:update', function (e, data) {
            // HACK SFCC-76513 moved GTM data to server as uuid is not reliably updated with AddProductBack
            if (data && data.gtm && data.gtm.data) {
                var qtyDiff = data.gtm.newQty - data.gtm.oldQty;
                if (qtyDiff > 0) {
                    addToCart(data.gtm.data, qtyDiff, ATC_LOCATION.CART);
                } else {
                    removeFromCart(data.gtm.data, Math.abs(qtyDiff));
                }
            }
        });
    },
    checkout: function () {
        $('body').on('checkout:connectConsultant:phoneNumber:success', function (e, consultant) {
            if (consultant) {
                connectConsultant(consultant, CONSULTANT_METHOD.PHONE_NUMBER);
            }
        });
        $('body').on('checkout:connectConsultant:zipCode:success', function (e, consultant) {
            if (consultant) {
                connectConsultant(consultant, CONSULTANT_METHOD.ZIP_CODE);
            }
        });
        $('body').on('checkout:connectConsultant:invalidPhone', function (e, data) {
            connectConsultantFailed(CONSULTANT_FAILED.INVALID_PHONE_NUMBER);
        });
        $('body').on('checkout:connectConsultant:notFound', function (e, data) {
            connectConsultantFailed(CONSULTANT_FAILED.IBC_NOT_FOUND);
        });
        $('body').on('checkout:connectConsultant:offline', function (e, data) {
            connectConsultantFailed(CONSULTANT_FAILED.IBC_INELIGIBLE);
        });
        $('body').on('checkout:shipping:complete', function (e, data) {
            updateCheckoutStep(CHECKOUT_STEPS.SHIPPING);
        });
        $('body').on('checkout:payment:complete', function (e, data) {
            updateCheckoutStep(CHECKOUT_STEPS.PAYMENT);
        });
        $(document).ready(function () {
            updateCheckoutStep();
        });
    },
    // events that should happen on every page
    // SFCC-16038: Must ensure that selectors used in 'all' section aren't duplicated in specific sections!
    all: function () {
        // Added first product to empty cart
        $('body').on('product:addProductToEmptyCart', function () {
            addFirstProductToEmptyCart();
        });

        // Add global GTM handler for AJAX errors
        $(document).ajaxError(function (e, jqXHR) {
            var statusCode = jqXHR.status;
            var event = {
                event: 'e_httpError',
                httpErrorCode: statusCode
            };
            dataLayer.push(event);
        });
        $('body').on('promotion:error', function (e, data) {
            if (data && data.gtm) {
                applyPromoCode(data.gtm.couponCode, false);
            }
        });
        $('body').on('promotion:success', function (e, data) {
            if (data && data.gtm) {
                applyPromoCode(data.gtm.couponCode, true);
            }
        });
    }
};


/**
 *
 * @description Initialize the tag manager functionality
 * @param {string} nameSpace The current name space
 */
exports.init = function (nameSpace) {
    var ns = nameSpace || pageContext.ns;
    if (ns && events[ns]) {
        events[ns]();
    }
    events.all();
};

/**
 *
 * @description Update product data attribute for specific element
 * @param {Object} element Element
 * @param {string} gtmData Data from attribute data-gtmdata
 */
exports.updateProductAttributes = function (element, gtmData) {
    var gtmDataObj = $.parseJSON(gtmData);
    if (element.length > 0) {
        element.attr('data-gtm-product-id', gtmDataObj.id);
        element.attr('data-gtm-product-name', gtmDataObj.name);
        if (gtmDataObj.category) {
            element.attr('data-gtm-product-category', gtmDataObj.category);
        }
        if (gtmDataObj.subcategory) {
            element.attr('data-gtm-product-subcategory', gtmDataObj.subcategory);
        }
        element.attr('data-gtm-product-sectioncode', gtmDataObj.sectionCode);
        element.attr('data-gtm-product-brand', gtmDataObj.brand);
    }
};
